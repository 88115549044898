<template>
  <div>
    <el-card v-if="$store.state.mode == 'PTE'">
      <h3 slot="header">{{ $t('PTE收藏') }}</h3>
      <el-row :gutter="40">

        <el-col :span="8" :class="{ 'margin-lt': index > 3 }" v-for="index in 5" :key="index">
          <el-card>
            <h3 class="lt pdtb flex flex-between">
              <div><i v-for="index1 in index" style="color:rgb(247, 186, 42)" :key="index1" class="el-icon-star-on"></i>
              </div>
              <div>（{{ (info["pte_" + index] || 0) }}）</div>
            </h3>
            <div class="flex flex-between margin-t flex-y-center">
              <el-link v-if="info['pte_' + index]" type="primary" @click="reset(index)">{{ $t('重置') }}<i
                  class="el-icon-refresh-left"></i></el-link>
              <div v-else></div>
              <el-link @click="to(index)">{{ $t('去练习') }}<i class="el-icon-arrow-right"></i></el-link>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>


    <el-card v-if="$store.state.mode == 'CCL'">
      <h3 slot="header">{{ $t('CCL收藏') }}</h3>
      <el-row :gutter="40">

        <el-col :span="8" :class="{ 'margin-lt': index > 3 }" v-for="index in 5" :key="index">
          <el-card>
            <h3 class="lt pdtb flex flex-between">
              <div><i v-for="index1 in index" style="color:rgb(247, 186, 42)" :key="index1" class="el-icon-star-on"></i>
              </div>
              <div>（{{ (info["ccl_" + index] || 0) }}）</div>
            </h3>
            <div class="flex flex-between margin-t flex-y-center">
              <el-link v-if="info['ccl_' + index]" type="primary" @click="reset(index)">{{ $t('重置') }}<i
                  class="el-icon-refresh-left"></i></el-link>
              <div v-else></div>
              <el-link @click="to1(index)">{{ $t('去练习') }}<i class="el-icon-arrow-right"></i></el-link>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>


    <el-card class="margin-lt">
      <h3 slot="header">{{ $t('自定义收藏夹') }}</h3>
      <el-row :gutter="40">

        <el-col :span="8" :class="{ 'margin-lt': index > 2 }" v-for="(item, index) in favorites" :key="index">
          <el-card>
            <h3 class="lt pdtb flex flex-between flex-v-center">
              <div>{{ item.title }}
              </div>

              <el-tooltip placement="bottom">
                <div slot="content">
                  <div class="pointer"> <a @click="add(item)" class="color-white ul-hover ">{{ $t('重命名') }}</a></div>
                  <div class="margin-t pointer"> <a class="color-white ul-hover" @click="remove(item)">{{ $t('删 除') }}</a>
                  </div>
                </div>
                <i class="el-icon-more pointer" style="font-size:30px;color:gray"></i>
              </el-tooltip>
            </h3>
            <div class="flex flex-between margin-t flex-v-center">
              <div class="st">{{ $t('共') + item.sources_count + $t('题') }}</div>
              <el-link v-if="item.sources_count" type="primary" @click="resetFavorite(item.id)">{{ $t('重置') }}<i
                  class="el-icon-refresh-left"></i></el-link>
              <div v-else></div>
              <el-link v-if="item.sources_count" @click="toFavorites(item.id)">{{ $t('去练习') }}<i
                  class="el-icon-arrow-right"></i></el-link>
            </div>
          </el-card>
        </el-col>

        <el-col :span="8" :class="{ 'margin-lt': favorites.length > 2 }">
          <el-card class="pointer">
            <div class="flex flex-h-center padding" @click="add">
              <i class="el-icon-plus" style="font-size:48px;color:#ccc;"></i>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      date: "",
      tableData: [{}, {}],
      tab: 0,
      info: {},
      favorites: []
    };
  },

  created() {
    this.query();
    this.query_favorites()
  },

  methods: {

    query_favorites() {
      this.$http.post("/api/source/mine/favorite/list").then((favorites) => {
        this.favorites = favorites;
      })
    },

    remove(item) {
      this.$confirm('', this.$t('确认删除此收藏夹') + '?', {
        showCancle: false,
        center: true,
        confirmButtonClass: "confirm-btn",
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'primary'
      }).then(() => {
        this.$http.post("/api/source/mine/favorite/remove", { id: item.id }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('删除成功') + '!'
          });
          this.query_favorites();
        })
      }).catch(() => {

      });
    },

    add(item) {
      if (this.$store.getters.getRole() > 0) {
        this.$prompt('', this.$t('创建收藏夹'), {
          inputValue: item ? item.title : '',
          center: true,
          roundButton: true,
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
        }).then(({ value }) => {
          if (value) {
            this.$http.post("/api/source/mine/favorite", { id: item ? item.id : 0, title: value }).then(() => {
              this.$message({
                type: 'success',
                message: this.$t("操作成功") + "!"
              });
              this.query_favorites();
            })
          }
        }).catch(() => {

        });
      } else {
        this.$vip_alert("添加单词本");
      }
    },

    toFavorites(id) {
      if (this.$store.state.mode == "CCL") {
        this.$router.push("/main/ccl?tag=favorite" + id);
      } else {
        this.$router.push("/main/pte?tag=favorite" + id);
      }
    },

    to(tag) {
      this.$router.push("/main/pte?tag=star" + tag);
    },

    to1(tag) {
      this.$router.push("/main/ccl?tag=star" + tag);
    },
    query() {
      this.$http.post("/api/source/favorites").then((info) => {
        if (info) {
          this.info = info;
        }
      })
    },

    reset(index) {

      this.$confirm(this.$t('删除后所有记录将会清空,确认删除此收藏记录') + '?', this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        confirmButtonClass: "confirm-btn",
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/source/favorite/remove", { stars: index }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('重置成功') + '!'
          });

          this.query()
        })
      }).catch(() => {

      });
    },
    resetFavorite(id) {
      this.$confirm(this.$t('删除后所有记录将会清空,确认删除此收藏记录') + '?', this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        confirmButtonClass: "confirm-btn",
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/source/mine/favorite/clear", { id: id }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('重置成功') + '!'
          });

          this.query_favorites()
        })
      }).catch(() => {

      });
    }
  },
};
</script>

<style scoped>
.header {
  padding: 0 28px;
  background-image: linear-gradient(0deg, #1ad0b3, #23ccd1), linear-gradient(#fff, #fff);
  border-radius: 10px;
  color: #fff;
}

.l-img {
  height: 160px;
  width: 120px;
}

.title {
  text-decoration: underline;
  text-underline-offset: 20px;
  text-decoration-color: #1ad0b3;
}
</style>
